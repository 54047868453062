import React, {FC} from 'react';
import {Box, Heading, HStack, Icon, Progress} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {MdInfo} from 'react-icons/md';
import BodyContainer from '../../../components/BodyContainer';
import PaymentAnalyticCard from '../../../components/PaymentAnalyticCard';
import TopMenu from '../../../components/TopMenu';
import TouchableHover from '../../../components/TouchableHover';
import LineGraph from '../../admin/components/LineGraph';
import EmployerOnboarding from '../components/EmployerOnboarding';
import EmployerAnalyticsController from 'puddleglum/api/employer/EmployerAnalyticsController';

const EmployerPortalHomeScreen: FC = () => {
	const {data: statsData, isLoading: areStatsLoading} = useQuery(
		['employerHomeStats'],
		async () => {
			const reply = await EmployerAnalyticsController.stats();
			return reply.data;
		},
	);

	const {data: payoutsByMonth} = useQuery(['employerHomePayoutsByMonth'], async () => {
		const reply = await EmployerAnalyticsController.getPayoutsByMonth();
		return reply.data;
	});

	if (!statsData || areStatsLoading) return <Progress size='xs' isIndeterminate />;

	return (
		<>
			<TopMenu>Allora Platform</TopMenu>
			<HStack w='auto' spacing={4} mt={4} pr={4}>
				<BodyContainer flex={1} p={4} textAlign='center'>
					<Heading>{statsData.employeeCount}</Heading>
					<Heading size='sm' color='gray.500'>
						Employees
					</Heading>
				</BodyContainer>
				<BodyContainer flex={1} p={4} textAlign='center'>
					<Heading>{Math.round(statsData.employeeUtilization)}%</Heading>
					<Heading size='sm' color='gray.500'>
						Employee Utilization Rate
					</Heading>
				</BodyContainer>
				<BodyContainer flex={1} p={4} textAlign='center'>
					<Heading>{statsData.payoutCount}</Heading>
					<Heading size='sm' color='gray.500'>
						Total Payouts
					</Heading>
				</BodyContainer>
			</HStack>

			<HStack w='full' spacing={4} mt={4} pr={4} alignItems='flex-start'>
				<BodyContainer flex={3} p={4}>
					<Heading size='md' mb={4} color='gray.500'>
						Payouts by Month
					</Heading>
					<LineGraph
						data={payoutsByMonth as any[]}
						xAxisKey='date'
						lines={[
							{
								dataKey: 'total',
								label: 'Total Payouts in USD',
								color: '#319795',
							},
						]}
						w='auto'
						h='400px'
						p={4}
						border='1px solid'
						borderColor='gray.100'
						borderRadius='md'
					/>
				</BodyContainer>
				<Box w='400px'>
					<BodyContainer border='solid 1px' p={4}>
						<HStack w='full' justifyContent='space-between'>
							<Heading size='md' color='gray.700'>
								Onboarding
							</Heading>
						</HStack>
						<EmployerOnboarding />
					</BodyContainer>
					<BodyContainer border='solid 1px' flex={2} p={4} mt={4}>
						<Heading size='md' color='gray.700'>
							Pending Invoices
							<Box float='right'>
								<TouchableHover label='This amount represent unpaid billing statements. This amount should be paid to you soon!'>
									<span>
										<Icon as={MdInfo} color='gray.500' />
									</span>
								</TouchableHover>
							</Box>
						</Heading>
						<PaymentAnalyticCard type='pending' scope='employer' />
					</BodyContainer>
					<BodyContainer border='solid 1px' flex={2} p={4} mt={4}>
						<Heading size='md' color='gray.700'>
							Recent Invoices
							<Box float='right'>
								<TouchableHover label='This is the amount you have been paid by Allora in the past 30 days.'>
									<span>
										<Icon as={MdInfo} color='gray.500' />
									</span>
								</TouchableHover>
							</Box>
						</Heading>
						<PaymentAnalyticCard type='recent' scope='employer' />
					</BodyContainer>
					<BodyContainer border='solid 1px' flex={2} p={4} mt={4}>
						<Heading size='md' color='gray.700'>
							Paid Invoices
							<Box float='right'>
								<TouchableHover label='This is the amount you have been by Allora for all time.'>
									<span>
										<Icon as={MdInfo} color='gray.500' />
									</span>
								</TouchableHover>
							</Box>
						</Heading>
						<PaymentAnalyticCard type='all' scope='employer' />
					</BodyContainer>
				</Box>
			</HStack>
		</>
	);
};

export default EmployerPortalHomeScreen;
