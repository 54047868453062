/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, {AxiosRequestConfig} from 'axios';
import {Glum} from 'puddleglum';
import {transformToQueryString, PaginatedResponse} from 'puddleglum/utils';

export default class DepositController {
	static async createPaymentIntent(
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post<{client_secret: string}>(`/api/employer/deposit/create-payment-intent`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}
}
