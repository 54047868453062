import React, {useState} from 'react';
import {Box, Button, HStack, Icon, Link, Progress, Text, VStack} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {MdCheckCircle, MdOutlineCircle} from 'react-icons/md';
import {Link as ReactLink} from 'react-router-dom';
import DepositPaymentModal from 'domains/employer/components/modals/DepositPaymentModal';
import EmployerOnboardingController from 'puddleglum/api/employer/EmployerOnboardingController';

const EmployerOnboarding = () => {
	const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);

	const {data: onboardingStatus} = useQuery(['employer-onboarding'], async () => {
		const reply = await EmployerOnboardingController.getStatus();
		return reply.data;
	});

	const isEverythingCompleted =
		onboardingStatus?.is_profile_completed &&
		onboardingStatus?.are_employees_added &&
		onboardingStatus?.is_deposit_paid;

	if (!onboardingStatus) return <Progress size='sm' isIndeterminate />;

	if (isEverythingCompleted)
		return (
			<Box mt={4}>
				<HStack>
					<Icon as={MdCheckCircle} color='green.500' boxSize={4} />
					<Text>You have completed all the onboarding steps!</Text>
				</HStack>
			</Box>
		);

	return (
		<>
			<VStack mt={4}>
				<HStack w='full' alignContent='start'>
					<Icon
						as={onboardingStatus.is_profile_completed ? MdCheckCircle : MdOutlineCircle}
						color={onboardingStatus.is_profile_completed ? 'green.500' : 'red.500'}
					/>
					<Link as={ReactLink} to='/settings' flex={1} p={0}>
						Complete Your Organization Profile
					</Link>
				</HStack>
				<HStack w='full' alignContent='start'>
					<Icon
						as={onboardingStatus.are_employees_added ? MdCheckCircle : MdOutlineCircle}
						color={onboardingStatus.are_employees_added ? 'green.500' : 'red.500'}
					/>
					<Link as={ReactLink} to='/locations' flex={1} p={0}>
						Add Your Employees
					</Link>
				</HStack>
				{onboardingStatus.deposit_amount > 0 && (
					<HStack w='full' alignContent='start'>
						<Icon
							as={onboardingStatus.is_deposit_paid ? MdCheckCircle : MdOutlineCircle}
							color={onboardingStatus.is_deposit_paid ? 'green.500' : 'red.500'}
						/>
						<Button
							variant='link'
							flex={1}
							p={0}
							onClick={() => setIsDepositModalOpen(true)}
							color='black'
							fontWeight='normal'
							justifyContent='start'
						>
							Pay Your Initial Deposit
						</Button>
					</HStack>
				)}
			</VStack>
			<DepositPaymentModal
				isOpen={isDepositModalOpen}
				onClose={() => setIsDepositModalOpen(false)}
				depositAmount={onboardingStatus.deposit_amount}
			/>
		</>
	);
};

export default EmployerOnboarding;
